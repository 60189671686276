<template>
  <div>
    <select-field v-model="local.fields" label="Группы полей" :attribute-id="820" :registry-id="819" :multiple="true" width="390px"></select-field>
    <select-field clearable :disabled="local.structures.length > 0" v-model="local.group" label="Группа подразделений" :attribute-id="826" :registry-id="825" :multiple="false" width="390px"></select-field>
    <select-field :disabled="!!local.group" v-model="local.structures" label="Структурное подразделение" :attribute-id="733" :registry-id="732" :multiple="true" width="390px"></select-field>
  </div>
</template>

<script>
import selectField from '@/components/InterfaceEditor/components/basic/a-select'
export default {
  props: ['model'],
  components: {
    selectField
  },
  name: 'TranslationRequestCmp',
  data () {
    return {
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
    },
    model () {
      this.local = this.model
    }
  }
}
</script>

<style scoped>

</style>
