import BasePlugin from '../BasePlugin'
import TranslationRequestCmp from './TranslationRequestCmp'

export default class TranslationRequest extends BasePlugin {
  async execute () {
    if (!this.context.getModel().id) {
      this.context.$message.error('Запись не сохранена')
      return false
    }
    const h = this.context.$createElement
    let values = {
      fields: [],
      group: null,
      structures: [],
      recordId: this.context.getModel().id
    }
    this.context.$msgbox({
      title: 'Транслирование заявки',
      message: h('p', { key: Math.random() }, [
        h(TranslationRequestCmp, { props: { model: values } })
      ]),
      showCancelButton: false,
      confirmButtonText: 'Отправить',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          if (
            values.fields.length === 0 ||
            (values.group === null &&
            values.structures.length === 0)
          ) {
            this.context.$message.error('Не все обязательные поля заполнены')
            return false
          }
          instance.confirmButtonLoading = true
          instance.confirmButtonText = 'Загрузка...'
          this.send(values)
            .then((response) => {
              let message = `Создано БДР: ${response.data.BDR || 0}`
              message += `; Создано БИР: ${response.data.BIR || 0}`
              message += `; Создано Календарный план: ${response.data.Plan || 0}`

              setTimeout(() => {
                let text = 'Заявка отправлена на заполнение'
                this.context.$msgbox({
                  type: 'info',
                  message: text
                })
              }, 300)

              setTimeout(() => {
                this.context.$message({
                  type: 'info',
                  message: message,
                  duration: 10000
                })
              }, 3000)
            })
            .catch((error) => { this.context.$message.error(error.response.data.message || 'Ошибка выполнения') })
            .finally(() => {
              instance.confirmButtonLoading = false
              done()
              this.context.getCard().$emit('cancelChanges')
            })
        } else {
          done()
        }
      }
    }).then(action => {

    })
  }
}
